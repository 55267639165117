import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLoginContext } from "../context/LoginContext";

const Register = () => {
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();

  const { setIsLoggedIn } = useLoginContext();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8).required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        "https://api.texmex.ai/api/v1/register",
        {
          email: values.email,
          password: values.password,
          role: "user",
        }
      );
      if (response?.status === 201) {
        localStorage.setItem("texmexTok", response?.data?.token);
        setIsLoggedIn(true);
        toast.success("User Registered Successfully");
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar={false} />
      <section className="login-wrap">
        <div className="container">
          <div className="login-wrap__inner">
            <div className="login-form">
              <h2>
                Welcome to <strong>TexMex</strong>
              </h2>
              <p>Create your account.</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-field">
                      <Field
                        type="email"
                        name="email"
                        className="form-input"
                        placeholder="Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="password"
                        name="password"
                        className="form-input"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="password"
                        name="confirmPassword"
                        className="form-input"
                        placeholder="Confirm Password"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-field">
                      <button
                        type="submit"
                        className="button button--primary"
                        disabled={isSubmitting}
                      >
                        Sign Up
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-links">
                <ul>
                  <li>
                    Already have an account? <Link to={"/login"}>Login</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="login-content">
              <div className="login-content-inner">
                <span className="brandName">
                  Tex<span>Mex</span>
                </span>
                <h1>Welcome to TexMex.</h1>
                <p>
                  Let’s get you all set up so you can verify your personal
                  account and begin setting up your profile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
