import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AboutSkeleton from "../components/skeleton/AboutSkeleton";

const AboutUs = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const getContent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.texmex.ai/api/v1/content-detail?title=About"
      );
      setContent(response?.data?.data?.description);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContent();
  }, []);
  return (
    <section className="landing-page">
      <div className="container">
        <div className="landing-wrap">
          <div className="landing">
            <h1>About Us</h1>
            <p style={{fontSize: 25}}>{loading ? <AboutSkeleton /> : content}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
