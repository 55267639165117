import React from "react";
import Skeleton from "react-loading-skeleton";

const AboutSkeleton = () => {
  return (
    <Skeleton
      style={{ borderRadius: "20px" }}
      baseColor="#93876c"
      highlightColor="#7c6e59"
      height={400}
      width={1200}
    />
  );
};

export default AboutSkeleton;
