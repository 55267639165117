import React from "react";
import LandingVector from "../assets/images/landing-vector.svg";
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <section className="landing-page">
      <div className="container">
        <div className="landing-wrap">
          <div className="landing-wrap__left">
            <h1>Unlocking AI-Powered Lead Generation</h1>
            <p>Welcome to <span className="color-primary">TexMex</span>, where cutting-edge AI technology meets lead generation excellence.</p>
            <div className="button-wrap">
              <Link to={'/about-us'} className='button button--secondary'>Learn More</Link>
              <Link to={'/login'} className='button button--primary'>Try It The System</Link>
            </div>
          </div>
          <div className="landing-wrap__right">
          <img src={LandingVector} alt="LandingVector" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
