import AboutUs from "../pages/AboutUs";
import Landing from "../pages/landing";
import Login from "../pages/Login";
import Register from "../pages/Register";

export const Router = [
    {
      path: "/",
      exact: true,
      children: [
        { path: "/", element: <Landing /> },
        { path: "/login", element: <Login /> },
        { path: "/register", element: <Register /> },
        { path: "/about-us", element: <AboutUs /> },
      ],
    },
  ];