import { useRoutes } from 'react-router-dom';
import './App.css';
import 'bootstrap/scss/bootstrap.scss';
import '../src/assets/scss/main.scss'
import Header from './layout/Header';
import { Router } from './router/routing';

function App() {

  const routing = useRoutes(Router);

  

  return (
    <div className="App">
      <Header />
      {routing}
    </div>
  );
}

export default App;
