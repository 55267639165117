import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginContext } from "../context/LoginContext";

const Header = () => {
  const token = localStorage.getItem("texmexTok");
  console.log(!!token, "lll");
  // const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [buttonName, setButtonName] = useState();

  const { setIsLoggedIn, isLoggedIn } = useLoginContext();

  const logoutUser = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("texmexTok")}`,
        },
      };
      const response = await axios.get(
        "https://api.texmex.ai/api/v1/logout",
        config
      );
      if (response?.status === 200) {
        localStorage.removeItem("texmexTok");
        setIsLoggedIn(false); // Update the authentication state
        toast.success("User Signed Out Successfully");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    setButtonName(isLoggedIn ? "Logout" : "Login");
  }, [isLoggedIn]);

  return (
    <header className="header">
      <Navbar expand="lg" className="header__navbar">
        <Container>
          <div className="header__inner">
            <Navbar.Brand href="">
              <Link to="/" style={{color:'black', textDecoration:'none'}}>
                Tex<span>Mex</span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-pages">
                <ul>
                  <li className="navbar-item">
                    <Link style={{ textDecoration: "none" }} to="/about-us">
                      About Us
                    </Link>
                  </li>
                </ul>
              </Nav>
              <Nav>
                <ul>
                  <li className="navbar-item">
                    {/* {isLoggedIn ? ( */}
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`login`}
                      onClick={isLoggedIn ? logoutUser : () => {}}
                    >
                      {buttonName}
                    </Link>
                  </li>
                  {!token && (
                    <li className="navbar-item">
                      <Link style={{ textDecoration: "none" }} to="/register">
                        Sign Up
                      </Link>
                    </li>
                  )}
                </ul>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
